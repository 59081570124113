"use client";

import { useEffect } from "react";
import "../styles/booking.css";
import Script from "next/script";

function BookingForm() {
  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "/assets/index.js";
    script.async = true;
    // @ts-ignore
    document?.getElementById("booking-script-root").appendChild(script);


    const link = document.createElement("link");
    link.rel = "stylesheet";
    link.href = "/assets/index.css";
    document.head.appendChild(link);
  }, []);

  return (
    <div className="flex w-full flex-col items-start">

      <Script>
        {`
              window.ttbsConfig = {
              isHorizontal: false,
              googleMapsApiKey: "AIzaSyAnGtY4uuH6yslCCtv0-C6wIRyVyQZB39M",
              apiKey: "92e017f9-3c31-4045-bb60-d1d0f3615e33",
              bookingPageUrl: "/booking",
                  locale: "en",
                  fonts: {
                    default: {
                      family: "DM Sans",
                      source:
                        "https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap",
                    },
                    heading: {
                      family: "DegularDisplay-Semibold",
                      source:
                        "https://static.travelthru.com/css?font=degular-complete/degular-display/web/DegularDisplay-Semibold.woff2",
                    },
                  },
                  colors: {
                    primary: "#000000",
                    secondary: {
                      100: "#F24F1D",
                      200: "#FFB709",
                      300: "#FEF5E9",
                    },
                    third: {
                      100: "#6F6F6F",
                      200: "#7D7D7D",
                      300: "#888888",
                      400: "#D9D9D9",
                      500: "#F3F3F3",
                    },
                  }
            };
          `}
      </Script>

      <div id="booking-script-root" className="w-full h-full max-w-screen-xl"></div>
    </div>
  );
}

export default BookingForm;