export const LeftArrowIcon = ({
  width = 48,
  height = 48,
}: {
  width?: number;
  height?: number;
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 48 48"
      fill="none"
    >
      <path
        d="M48 24C48 37.2548 37.2548 48 24 48C10.7452 48 0 37.2548 0 24C0 10.7452 10.7452 0 24 0C37.2548 0 48 10.7452 48 24Z"
        fill="black"
      />
      <path
        d="M33.5985 23.9379C33.5869 23.4463 33.392 22.9763 33.0515 22.6191L21.0478 10.2065C20.6785 9.82767 20.1729 9.60969 19.642 9.60032C19.111 9.59094 18.598 9.79093 18.2153 10.1564C17.8326 10.522 17.6115 11.0232 17.6004 11.5503C17.5894 12.0773 17.7893 12.5872 18.1564 12.9682L28.8315 24L18.1564 35.0318C17.7893 35.4128 17.5894 35.9227 17.6004 36.4497C17.6115 36.9768 17.8326 37.478 18.2153 37.8436C18.598 38.2091 19.111 38.4091 19.642 38.3997C20.1729 38.3903 20.6785 38.1723 21.0478 37.7935L33.0515 25.3809C33.2349 25.1887 33.3778 24.962 33.4718 24.7142C33.5657 24.4664 33.6088 24.2025 33.5985 23.9379Z"
        fill="white"
      />
    </svg>
  );
};
